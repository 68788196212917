.container{
    @apply flex flex-col mx-44; 
    .header{
        .header-title{
            @apply mb-4;
            color: #2E2C2C;
            font-size: 64px;
            font-weight: 900;
        }

        .header-title span {
            @apply text-blue_geek ;
        }

        .header-body{
            @apply text-xl font-medium text-gray_main mb-13;
        }

        .header-body span{
            @apply cursor-pointer;
            color: #391085;
        }
    }

  
}