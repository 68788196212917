.container{
    @apply flex;
    .header{
        @apply flex flex-col items-center justify-center mr-6;

        .number{
            @apply m-0 text-lg font-semibold;
            color: #282828;
        }

        .name{
            @apply  font-semibold m-0 text-lg;
            color: #282828;
        }
    }

    .body{

        @apply m-4;

        .title{
            @apply m-0 text-lg font-semibold;
        }

        .description{
            @apply m-0;
        }
    }
}