.container{
    @apply flex flex-col justify-center items-center pb-6 ;
    width: 100%;
    height: 28rem;

    span{
        margin-top: 12px;
    }    
}

