/* .ant-menu-light.ant-menu-inline .ant-menu-item {
    height: 60px;
    font-size: 20px;
    font-weight: 600;
    
}

.ant-menu-submenu-title{
    height: 40px !important;
    font-size: 22px;
}



*/
.ant-menu-item  {
    color: #391085;
    width: 100% !important;
    font-weight: 500;
    height: 68px !important;
    line-height: 68px !important;
    margin: 0 !important;
}

.ant-menu-item-icon{
    margin-right: 20px;
    margin-left: 14px;
}

.ant-menu-item-selected{
    color: white !important;
    font-weight: 600;
    border-radius: 0 !important;
    background-color: #391085 !important;
}

