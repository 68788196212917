.container{
    @apply shadow-xl w-fit overflow-hidden h-screen fixed;
    border-right: 1px solid #dadcdf;

    background: linear-gradient(0deg, #f4f5f8, #f4f5f8),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);

    // border-right: 1px solid black;
    z-index: 2;


    .logo-wrapper{
        @apply flex justify-center pt-8   flex-col;

        .title{
            @apply text-xl text-purple_golden font-bold pl-6;
        }
    }

   

    .menu{
        @apply  text-lg text-gray_main font-semibold overflow-hidden;
        
        background: linear-gradient(0deg, #f4f5f8, #f4f5f8),
            linear-gradient(0deg, #FFFFFF, #FFFFFF);
        border-right: 4px solid rgba(0, 0, 0, 0.04);
        width: 360px;
    }
}