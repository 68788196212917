.container{
    @apply flex flex-col items-center shadow-2xl;
    border-radius: 36px;
    background-color: white;
    width: 800px;
    height: 728px;


    .header{

        @apply flex items-center justify-center flex-col mt-12 mb-8;

        .image{
            @apply w-52 h-auto;
            filter: brightness(0) saturate(100%) invert(15%) sepia(60%) saturate(3007%) hue-rotate(250deg) brightness(86%) contrast(118%);
        }

        .text{
            @apply text-3xl font-semibold text-purple_golden mt-0;
        }
    }

    .footer{
        @apply w-full;

        .divider{
            @apply text-gray_main text-base font-medium;
        }

        .button-wrapper{
            @apply flex justify-center mt-10;
            .btn{
                @apply w-56 h-16 flex justify-center items-center mx-7 text-gray_third;
                border-radius: 40px;

                .icon{
                    margin-right: 10px;
                }
            }


        }
    }
}


