.container{
    @apply pr-20 pb-32 pt-10 pl-20;
    background-color: #f2f4f7;

    .header{
        .title{
            @apply font-bold m-0;
            font-size: 32px;
            color : #2E2C2C;
        }
    }

    .body{
        @apply mt-4;
    }
}