.container {
    @apply py-8 pl-8 shadow-xl z-1;
    background-color: white;
    width: 100%;
    position: sticky;
    top: 0;

    span {
        @apply text-xl font-medium text-gray_main;
    }

    span:last-child {
        @apply text-black;
    }
}