.container{
    @apply px-48 pt-20 flex flex-col ;
    background-color: #fcfafa;
    .header{
        @apply pb-6 flex items-center ;
        .title{
            @apply font-bold ;
            color: #5F6368;
            font-size: 32px;

            span{
                @apply text-blue_geek;
            }
        }

        .sub-title{
            @apply font-light text-purple_golden m-0;
            font-weight: 700;
            font-size: 20px;
            min-width: 320px;

        }

        .sort-container{
            @apply flex items-center;
            .title{
                @apply font-light text-purple_golden m-0 pr-12;
                font-weight: 700;
                font-size: 20px;
            }

            .button{
                @apply shadow-sm mr-6;

                width: 165px;
                height: 52px;
                background: #ffffff;
                border: none;
                color: rgba(0, 0, 0, .8);
                border-radius: 37px;
            }

            .active{
                @apply text-blue_geek font-semibold tracking-wide;
                border : 1px solid #10239E;
            }
        }


    }
    
    .body-container{
        @apply flex mt-2;
        .filter-wrapper{
            @apply pb-14;
            min-width: 320px;
        }
        .course-container{
            @apply grid grid-cols-1 gap-x-10 mb-12;
            width: 70%;
            justify-items: center;        
        }
    }

}