.container{

    @apply shadow-xl w-fit overflow-hidden h-screen;
    position: sticky;
    top: 0;

    .logo-wrapper{
        @apply flex items-center pt-8 pb-8 ml-4;
        .logo{
            @apply w-20 h-auto;
            filter: brightness(0) saturate(100%) invert(18%) sepia(22%) saturate(6647%) hue-rotate(248deg) brightness(83%) contrast(120%);
        }

        .title{
            @apply text-xl text-purple_golden font-bold;
        }
    }

   

    .menu{
        @apply  text-lg text-gray_main font-medium overflow-hidden;
        border-right: 4px solid rgba(0, 0, 0, 0.04);
        width: 400px;
    }
}