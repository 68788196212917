.container{
    @apply   grid grid-cols-12 grid-rows-1 mb-10 px-4 py-6;
    max-height: 160px;
    width: 80%;
    .image-wrapper{
        @apply col-start-1 col-span-3 cursor-pointer h-full;

        .image{
            @apply h-full rounded-2xl ;
            width: 100%;
            // margin-top: auto;
                
        }
    }

    .body-wrapper{
        @apply   col-start-4 col-span-9 ml-6 flex flex-col h-full;

        .header-wrapper{
            @apply flex justify-between;
            .coursename{
                @apply text-xl tracking-wide font-semibold m-0 cursor-pointer;
            }

            .tag{
                @apply flex items-center justify-center py-1 px-3 rounded-md  text-sm;
                background-color: #FFEFE0;
                color: #FF7800;
            }
            
        }
        
        .description-wrapper{
            @apply mt-2 mb-4;
            .description{
                @apply text-gray-500 mt-0 tracking-tight text-base;
            }
            
        }

        .footer-wrapper{
            @apply flex justify-between items-end ;

            
            .avatar-wrapper{
                @apply flex items-center w-fit p-1 pr-3 rounded-lg cursor-pointer;
                border: 1px solid #ccc;
                margin-top: auto;
                
                .name{
                    @apply  m-0 pl-2 text-sm tracking-tight;
                }
            }

            .action-wrapper{
                @apply w-fit h-fit flex;
                .button{
                    @apply flex items-center justify-center px-3 py-5  ml-2;
                }
            }
        }
    }

}