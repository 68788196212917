.container{
    @apply flex w-full h-full;
    .left-wrapper{
        @apply flex flex-col ;
        height: 100vh;
        // @apply bg-gray_secondary;
        background-color: #f7f7f7;
        .header{
            @apply mt-7;
            .title{
                @apply ml-36 font-extrabold text-purple_golden mb-10 ;
                font-size: 52px;
                line-height: 64px;
            }
        }

        .body{
            flex: 1;
            .title{
                @apply text-black font-bold ml-36 mt-0 mb-5;
                font-size: 32px;
            }

            .content{
                @apply text-gray_main text-lg ml-36 mt-0;
                max-width: 420px;
            }

            .image{
                @apply ml-10 ;
                // width: 700px;
                height: 500px;
                background-color: white;
            }
        }

        .footer{
            ul{
                @apply flex ;
                list-style-type: none;
                text-decoration: none;

                li{
                    @apply mx-11 text-sm text-gray_main font-medium;
                }
            }
        }
    }

    .right-wrapper{
        @apply flex flex-col  items-center;

        .footer{
            @apply w-full;
        
            .divider{
                @apply text-gray_main text-base font-medium;
            }
        
            .button-wrapper{
                @apply flex justify-center mt-10;
                .btn{
                    @apply w-56 h-16 flex justify-center items-center mx-7 text-gray_third;
                    border-radius: 40px;
        
                    .icon{
                        margin-right: 10px;
                    }
                }
        
        
            }
        }
    }
}

